import { HotelData } from "@/app/[bookingType]/search/context/searchProvider";
import { Viewport } from "@/generated/search.openapi";
import { Loader } from "@googlemaps/js-api-loader";
import Supercluster from "supercluster";

export type BasicLatLng = {
  lat: number;
  lng: number;
};

export const googleMapLoader = new Loader({
  apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
  version: "weekly",
});

export const defaultMapId = "37d922de32ddd2ed";
export const resultsMapId = "95e4d5bded1754b6";
export const resultsMapIdV2 = "343d7a54b8f94b5e";
export const travelHistoryMapIdV2 = "fb50f4becd0f3205";
export const gondolaMapped = "b3f3cd1aec4f6976";

type MapDimensions = {
  width: number;
  height: number;
};

export const getCenterAndZoomFromCoordinates = (
  coordinates: BasicLatLng[],
  mapDim: MapDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  }
): {
  center: google.maps.LatLng;
  zoom: number;
} => {
  const bounds = new google.maps.LatLngBounds();
  coordinates.forEach((coordinate) => {
    bounds.extend(coordinate);
  });
  const center = bounds.getCenter();
  const zoom = calculateZoomLevel(bounds, mapDim);
  return { center, zoom };
};

export const calculateZoomLevel = (
  bounds: google.maps.LatLngBounds,
  mapDim: MapDimensions
): number => {
  // Dimensions of the world in the map, in pixels at zoom level 0.
  const WORLD_DIM = { height: 256, width: 256 };
  // Maximum allowed zoom level.
  const ZOOM_MAX = 18;

  // Function to calculate the latitude in radians.
  const latRad = (lat: number): number => {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  };

  // Function to calculate the zoom level.
  const zoom = (mapPx: number, worldPx: number, fraction: number): number => {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  };

  // Get the north-east and south-west corners of the bounds.
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  // Calculate the fraction of the world covered by the latitude and longitude bounds.
  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  // Calculate the zoom level for both latitude and longitude.
  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  // Return the minimum of the calculated zoom levels and the maximum allowed zoom level.
  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};
// Set the map viewport given northeast and southwest coordinates
export const setMapViewport = (
  map: google.maps.Map,
  viewport: { northeast: BasicLatLng; southwest: BasicLatLng }
): void => {
  const bounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(viewport.southwest.lat, viewport.southwest.lng), // Southwest coordinates
    new google.maps.LatLng(viewport.northeast.lat, viewport.northeast.lng) // Northeast coordinates
  );
  map.fitBounds(bounds);
};

// Function to convert degrees to radians.
const degreesToRadians = (degrees: number): number => {
  return (degrees * Math.PI) / 180;
};

export const distanceBetweenPointsInMiles = (
  point1: BasicLatLng,
  point2: BasicLatLng
): number => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = degreesToRadians(point2.lat - point1.lat);
  const dLon = degreesToRadians(point2.lng - point1.lng);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(point1.lat)) *
      Math.cos(degreesToRadians(point2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceInKm = R * c;
  const distanceInMiles = distanceInKm * 0.621371; // Convert to miles
  return distanceInMiles;
};

export const getViewportFromBounds = (
  bounds: google.maps.LatLngBounds
): Viewport => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return { north: ne.lat(), east: ne.lng(), south: sw.lat(), west: sw.lng() };
};

export const getBoundsFromViewport = (
  viewport: Viewport
): google.maps.LatLngBounds => {
  return new google.maps.LatLngBounds(
    new google.maps.LatLng(viewport.south, viewport.west),
    new google.maps.LatLng(viewport.north, viewport.east)
  );
};

export const convertPointsToGeoJson = (
  MapElementsData: HotelData[]
): Array<Supercluster.PointFeature<HotelData>> => {
  return MapElementsData.map((data) => ({
    type: "Feature",
    properties: { ...data },
    id: data.vervotechPropertyId,
    geometry: {
      type: "Point",
      coordinates: [
        data.coordinates?.longitude as number,
        data.coordinates?.latitude as number,
      ],
    },
  }));
};

export const convertBoundsToGeoJsonBBox = (
  bounds: google.maps.LatLngBounds
): GeoJSON.BBox => {
  // Get the southwest and northeast corners of the bounds
  const sw = bounds.getSouthWest();
  const ne = bounds.getNorthEast();

  // Extract the latitude and longitude from the corners
  const minLat = sw.lat();
  const minLng = sw.lng();
  const maxLat = ne.lat();
  const maxLng = ne.lng();

  // Construct the GeoJSON BBox
  const geoJsonBBox: GeoJSON.BBox = [minLng, minLat, maxLng, maxLat];

  return geoJsonBBox;
};

export enum MapColorEnum {
  ORANGE = "orange",
  BLUE = "blue",
  WHITE = "white",
}

type MapMarker = {
  position: BasicLatLng;
  color: MapColorEnum;
  type: "hotel" | "airport";
} & google.maps.MarkerOptions;

type MapPolyline = {
  from: BasicLatLng;
  to: BasicLatLng;
  color: MapColorEnum;
  type?: "default" | "dotted";
};

export const getPolyline = ({
  from,
  to,
  color,
  type,
}: MapPolyline): google.maps.Polyline =>
  new google.maps.Polyline({
    path: [to, from],
    geodesic: true,
    strokeColor: color === MapColorEnum.ORANGE ? "#DCA37A" : "#7A84DC",
    strokeOpacity: type === "dotted" ? 0 : 1,
    strokeWeight: 2,
    clickable: false,
    icons: [
      type === "dotted"
        ? {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 4,
            },
            offset: "0",
            repeat: "20px",
          }
        : {},
      {
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          strokeOpacity: 1,
          strokeColor: color === MapColorEnum.ORANGE ? "#DCA37A" : "#7A84DC",
          fillColor: color === MapColorEnum.ORANGE ? "#DCA37A" : "#7A84DC",
          fillOpacity: 1,
          scale: 4,
          anchor: new google.maps.Point(0, 2),
        },
        offset: "50%",
      },
    ],
  });

export const getMarker = ({
  position,
  color,
  type,
  ...options
}: MapMarker): google.maps.Marker => {
  const markerAnchor = new google.maps.Point(24, 49.5);
  return new google.maps.Marker({
    position,
    icon: {
      url: `/assets/map-icons/${type}-pin/${color}.svg`,
      anchor: markerAnchor,
    },
    ...options,
  });
};

export const northAmericaViewport: Viewport = {
  east: -50,
  north: 70,
  south: 5,
  west: -170,
};
