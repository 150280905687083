import { HotelSummary, TransferPartner } from "@/generated/search.openapi";
import {
  calculateNumberOfNights,
  capitalizeEachWord,
  cn,
  convertJsonToUrlParams,
  convertUTCToLocalDateIgnoringTimezone,
  formatCurrency,
  formatNumber,
  getRating,
  getTransferPartnersFromLoyalty,
  loyaltyAccountsColorMapping,
} from "@/lib/utils";
import React, { HTMLAttributes, MouseEvent, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import HotelCardSkeleton from "./HotelCardSkeleton";
import { format } from "date-fns";
import { getApproximatePercentile } from "@/app/[bookingType]/search/[searchId]/helper";
import { theme } from "@/themes";
import ArrowCircleLeft from "@/components/ui/svgComponents/ArrowCircleLeft";
import ArrowCircleRight from "@/components/ui/svgComponents/ArrowCircleRight";
import { X } from "lucide-react";
import "@/styles/hotelMapCard.css";
import Star from "@/components/ui/svgComponents/Star";
import Calendar from "@/components/ui/svgComponents/Calendar";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useSearch } from "@/app/[bookingType]/search/context/searchProvider";
import { LoyaltyProgramEnum, Tag } from "@/generated/email_parser.openapi";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/toolTip";

type Props = {
  hotel: HotelSummary;
  setHoveredHotelId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  checkin?: Date;
  checkout?: Date;
  numberOfAdults?: number;
  className?: HTMLAttributes<HTMLDivElement>["className"];
  innerClassName?: HTMLAttributes<HTMLDivElement>["className"];
  closeCard?: () => void;
  showDate?: boolean;
  isTallerImage?: boolean;
  disableOnClick?: boolean;
  availableTransferPartners?: TransferPartner[];
  showTags?: boolean;
};

const HotelCard: React.FC<Props> = ({
  hotel,
  className,
  innerClassName,
  setHoveredHotelId,
  closeCard,
  showDate,
  isTallerImage,
  disableOnClick,
  checkin,
  checkout,
  numberOfAdults,
  availableTransferPartners,
  showTags,
}) => {
  const { inView, ref } = useInView({
    initialInView: false,
    fallbackInView: false,
  });

  const checkInDate = convertUTCToLocalDateIgnoringTimezone(hotel.checkin!);
  const checkOutDate = convertUTCToLocalDateIgnoringTimezone(hotel.checkout!);
  const nights = calculateNumberOfNights(checkInDate, checkOutDate);

  const { filters } = useSearch();

  const onClick: React.MouseEventHandler<HTMLImageElement> = (e) => {
    e.stopPropagation();
    if (disableOnClick) return;
    window.open(
      `/hotel/details/${hotel.vervotechPropertyId}${convertJsonToUrlParams({
        numberOfAdults: numberOfAdults || 1,
        checkin: checkin ? format(checkin, "yyyy-MM-dd") : "",
        checkout: checkout ? format(checkout, "yyyy-MM-dd") : "",
      })}`,
      "_blank"
    );
  };
  const images = useMemo(() => {
    if (inView) {
      return hotel.images
        ?.filter((img) => {
          if (filters?.selectedImageCategories === undefined) return true;
          return img.category === filters.selectedImageCategories;
        })
        .map((img) => img.url);
    }
    return [];
  }, [filters?.selectedImageCategories, hotel.images, inView]);

  const imageGalleryItems: ReactImageGalleryItem[] = useMemo(() => {
    if (!images?.length) return [];
    return images.map((image) => ({
      original: image,
      lazyLoad: true,
      infinite: false,
      renderItem: (item) => (
        <img
          src={item.original}
          alt={item.originalAlt}
          className={cn(
            "h-[230px] w-full cursor-pointer rounded-lg object-cover",
            isTallerImage && "h-[275px]"
          )}
        />
      ),
    })) as ReactImageGalleryItem[];
  }, [images, isTallerImage]);

  const approximatePercentile = getApproximatePercentile(hotel, true);

  const transferPartners = useMemo(
    () =>
      getTransferPartnersFromLoyalty(
        hotel.loyaltyProgram!,
        availableTransferPartners ?? []
      ).map((partner) => {
        const lp = capitalizeEachWord(
          partner.fromLoyaltyProgram.split("_").join(" ").toLocaleLowerCase()
        );
        if (lp === "American Express") return "Amex";
        return lp;
      }),
    [hotel.loyaltyProgram, availableTransferPartners]
  );

  const hotelTags = useMemo(() => {
    return hotel.tags?.filter((tag) => tag.stayTag === "Great Points Deal");
  }, [hotel.tags]);

  return (
    <div
      className={cn(
        "w-full",
        showDate
          ? isTallerImage
            ? "h-[465px]"
            : "h-[420px]"
          : isTallerImage
            ? "h-[440px]"
            : "h-[395px]",
        className
      )}
      ref={ref}
    >
      {inView ? (
        <div
          className={cn(
            "relative flex h-full w-full cursor-pointer select-none flex-col gap-3 rounded-lg",
            disableOnClick && "cursor-default",
            innerClassName
          )}
          onMouseEnter={() => setHoveredHotelId?.(hotel.vervotechPropertyId)}
          onMouseLeave={() => setHoveredHotelId?.(undefined)}
          onClick={onClick}
        >
          <ImageGallery
            autoPlay={false}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={false}
            showBullets={false}
            lazyLoad={true}
            items={imageGalleryItems}
            showNav={true}
            infinite={false}
            disableSwipe
            renderLeftNav={(onClickLeft, disabled) => {
              if (disabled) return null;
              return (
                <ArrowCircleLeft
                  className="absolute left-1 top-1/2 z-[9] h-8 w-8 -translate-y-1/2 transform cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickLeft(e as unknown as MouseEvent<HTMLImageElement>);
                  }}
                />
              );
            }}
            renderRightNav={(onClickRight, disabled) => {
              if (disabled) return null;
              return (
                <ArrowCircleRight
                  className="absolute right-1 top-1/2 z-[9] h-8 w-8 -translate-y-1/2 transform cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickRight(e as unknown as MouseEvent<HTMLImageElement>);
                  }}
                />
              );
            }}
          />
          {showTags && hotelTags?.length ? (
            <div className="absolute left-3 top-3 flex flex-col items-start justify-start gap-1">
              {hotelTags.map((tag, i) => (
                <HotelTag key={i} {...tag} />
              ))}
            </div>
          ) : null}
          {closeCard && (
            <div className="absolute right-1 top-1 flex items-center gap-2 p-2">
              <div
                className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-white"
                onClick={(e) => {
                  e.stopPropagation();
                  closeCard();
                }}
              >
                <X className="h-4 w-4" />
              </div>
            </div>
          )}
          <div className="flex w-full flex-col p-2 pt-0">
            <div className="no-scrollbar mb-1 flex h-7 items-center gap-2 overflow-auto">
              {hotel.loyaltyProgram && (
                <div
                  className="flex h-[25px] items-center justify-center whitespace-nowrap rounded-full border border-white/30 px-3 py-1.5 text-[10px] font-semibold text-primary"
                  style={{
                    backgroundColor:
                      loyaltyAccountsColorMapping[
                        hotel.loyaltyProgram as LoyaltyProgramEnum
                      ],
                  }}
                >
                  {hotel.loyaltyProgram}
                </div>
              )}
              {transferPartners.length ? (
                <>
                  <div className="h-[20px] min-w-px bg-white/40"></div>
                  {transferPartners.map((transferPartner, i) => (
                    <div
                      className="flex h-[25px] items-center justify-center whitespace-nowrap rounded-full border border-white/30 px-3 py-1.5 text-[10px] font-semibold text-primary backdrop-blur-sm"
                      key={i}
                    >
                      {transferPartner}
                    </div>
                  ))}
                </>
              ) : null}
            </div>
            <div className="flex w-full items-start justify-between gap-2.5 self-stretch">
              <div className="flex h-12 flex-col text-base font-medium text-primary">
                <span className="line-clamp-2">{hotel.propertyName}</span>
              </div>
              {hotel.averageGuestRating && (
                <div className="mt-0.5 flex flex-col justify-center gap-x-1 text-right text-sm font-normal text-primary">
                  <span className="flex items-center justify-end gap-1">
                    <Star className="h-3 w-3" color="#faff1a" />
                    {getRating(hotel.averageGuestRating)}
                  </span>
                </div>
              )}
            </div>
            <div className="line-clamp-1 text-sm font-medium text-light-2">
              {`${hotel.cityName}, ${hotel.countryCode}  • ${hotel.stars}-star hotel`}
            </div>
            {showDate && (
              <div className="my-1 flex items-center gap-2">
                <Calendar
                  color={theme.extend.textColor["light-2"]}
                  className="h-5 w-5"
                />
                <span className="text-xs text-primary">
                  {format(checkInDate, "MMM dd")} -{" "}
                  {format(checkOutDate, "MMM dd")} ({nights} night stay)
                </span>
              </div>
            )}
            {hotel.nightlyRate || hotel.lowestNightlyPoints ? (
              <div className="flex w-full items-center gap-2 py-0.5">
                {!!hotel.nightlyRate && (
                  <div
                    className={cn(
                      "flex flex-1 flex-col border-line",
                      !!hotel.lowestNightlyPoints && "border-r"
                    )}
                  >
                    <div className="flex items-center justify-start gap-1">
                      <img
                        src="/assets/dollar-white.svg"
                        alt="rate"
                        className="-mr-0.5 h-4 w-2.5"
                      />
                      <span className="text-base text-primary">
                        {formatNumber(hotel.nightlyRate, 0)}
                        <span className="h-4 text-xs text-light-2">
                          {" "}
                          /night
                        </span>
                      </span>
                    </div>
                    <div className="h-4 text-[10px] text-light-2">
                      {`${formatCurrency(hotel.nightlyRate, "USD", 0)} total w/ tax & fees`}
                    </div>
                  </div>
                )}
                {!!hotel.lowestNightlyPoints && (
                  <div className="flex flex-1 flex-col">
                    <div className="flex items-center justify-start gap-1">
                      <img
                        src="/assets/points-white.svg"
                        alt="rate"
                        className="h-4 w-4"
                      />
                      <span className="text-base text-primary">
                        {formatNumber(hotel.lowestNightlyPoints, 0)}
                        <span className="h-4 text-xs text-light-2">
                          {" "}
                          pts/night
                        </span>
                      </span>
                    </div>
                    <div className="flex h-4 justify-between gap-2 text-[10px]">
                      <span className="text-light-2">
                        {formatNumber(hotel.lowestPoints!, 0)} pts total
                      </span>
                      {hotel.lowestPointsAmount?.conversionRate && (
                        <div
                          className={cn(
                            "flex h-4 flex-col items-center justify-center whitespace-nowrap rounded-[3px] px-1 text-[11px] font-normal text-dark",
                            approximatePercentile === "high"
                              ? "bg-value-good"
                              : "bg-value-default"
                          )}
                        >
                          {hotel.lowestPointsAmount?.conversionRate} ¢/pt
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="mt-4 flex text-sm text-[#FF6D6D]">
                We are sold out for these dates
              </div>
            )}
          </div>
        </div>
      ) : (
        <HotelCardSkeleton />
      )}
    </div>
  );
};

const HotelTag: React.FC<Tag> = ({ stayTag, tagReason }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  return (
    <Tooltip delayDuration={0} open={tooltipOpen}>
      <TooltipTrigger
        onClick={(e) => {
          e.stopPropagation();
          setTooltipOpen((prev) => !prev);
        }}
        onMouseLeave={() => setTooltipOpen(false)}
      >
        <div className="flex h-5 items-center justify-center rounded-[3px] bg-white px-1.5 text-xs font-normal shadow">
          {stayTag}
        </div>
      </TooltipTrigger>
      <TooltipContent className="max-w-[300px] lg:max-w-[400px]">
        {tagReason}
      </TooltipContent>
    </Tooltip>
  );
};

export default HotelCard;
